const openAccordingCollapse = () => {
    for (const collapse of document.querySelectorAll('.collapse-box, .collapse-box-statistics')) {
        if (collapse.querySelector('*[id*="open"') && !window.location.hash) {
            collapse.querySelector('header[id^=trigger-open]').classList.remove('collapsed')
            collapse.querySelector('header[id^=trigger-open]').setAttribute('aria-expanded', true)
            collapse.querySelector('aside[id^=open]').classList.add('collapse', 'show')
        }
    }
}

const collapseAddNameToItems = () => {
    for (const elem of document.querySelectorAll(".collapse-box header[id^='trigger'] a")) {
        if (!elem.hasAttribute('name')) {
            elem.setAttribute('name', elem.getAttribute('href').substring(1))
            elem.addEventListener('click', (event) => {
                event.preventDefault()
                history.pushState ?
                    history.pushState(null, null, event.target.getAttribute('href')) :
                    location.hash = event.target.getAttribute('href')
            })
        }
    }
}

const collapseOpenItemWhenFragment = () => {
    const hash = window.location.hash
    if (hash) {
        const node = document.querySelector(`.collapse-box header[id^='trigger'] a[href='${hash}']`)
        node && node.click()
    }
}

export { openAccordingCollapse, collapseAddNameToItems, collapseOpenItemWhenFragment }
